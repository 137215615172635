body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px; } }

.App {
  height: calc(100vh - 120px); }

.content-container {
  height: calc(100vh - 120px);
  margin-top: 10px; }

.ui.grid {
  height: calc(100vh - 120px); }

.ui.toggle.checkbox.auth-toggle label:before {
  height: .75rem;
  top: .25rem; }

.ui.toggle.checkbox.auth-toggle label:after {
  height: 1.25rem; }

