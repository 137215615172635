@media (min-width: 576px) { .modal-dialog { max-width: 500px; } }

.App {
  height: calc(100vh - 120px);
}

.content-container {
  height: calc(100vh - 120px);
  margin-top: 10px;
}

.ui.grid {
  height: calc(100vh - 120px);
}



.ui.toggle.checkbox.auth-toggle label:before{
  height: .75rem ;
  top: .25rem;
}

.ui.toggle.checkbox.auth-toggle label:after{
  height: 1.25rem ;
}

